<template>
  <!-- TODO: Apply dynamic title here -->
  <div class="members-header">
    <GlobalDynamicHeader :title="workstationBusinessName"/>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import GlobalDynamicHeader from '@/core/components/common/header/GlobalDynamicHeader.vue';
import { USER_WORKSTATION } from '@/store/modules/user-workstation';

export default defineComponent({
  components: {
    GlobalDynamicHeader
  },
  computed: {
    ...mapGetters(USER_WORKSTATION, ['userWorkstations', 'activeUserWorkstation']),

    isDefaultWorkStation() {
      const nameConstant = 'Default';
      return this.activeUserWorkstation?.name === nameConstant;
    },

    hasUserBusinessDetails() {
      return this.activeUserWorkstation?.user?.userBusinessDetails !== null;
    },

    teamName() {
      return this.activeUserWorkstation?.user?.userBusinessDetails?.teamName;
    },

    companyName() {
      return this.activeUserWorkstation?.user?.userBusinessDetails?.companyName;
    },

    businessAddress() {
      return this.activeUserWorkstation?.user?.userBusinessDetails?.businessAddress;
    },

    businessName() {
      return this.activeUserWorkstation?.user?.userBusinessDetails?.businessName;
    },

    workstationBusinessName() {
      const {
        isDefaultWorkStation,
        hasUserBusinessDetails,
        teamName,
        companyName,
        businessAddress,
        businessName
      } = this;

      if (!hasUserBusinessDetails && isDefaultWorkStation) {
        return `${this.activeUserWorkstation?.user.firstName} ${this.activeUserWorkstation?.user.lastName}`;
      }
      if (isDefaultWorkStation && teamName !== null && teamName !== '') {
        return teamName;
      }
      if (isDefaultWorkStation && companyName !== null && companyName !== '') {
        return companyName;
      }
      if (isDefaultWorkStation && businessName !== null && businessName !== '') {
        return businessName;
      }
      if (isDefaultWorkStation && businessAddress !== null && businessAddress !== '') {
        return businessAddress;
      }
      return this.activeUserWorkstation?.name;
    },
  }
});
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/mixins/" as *;

.members-header {
  width: 100%;
}
@include media-md-max-width() {
  .members-header {
    margin-left: 10px
  }
}
@include media-sm-max-width() {
  .members-header {
    margin-left: 20px
  }
}
</style>
